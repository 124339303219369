* {
  box-sizing: border-box;
  /* outline: 1px dashed #f00; */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Segoe UI Variable Static Text Light",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}
