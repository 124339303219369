.dashboard {
  padding: 64px 20px 20px;
  grid-template-columns: 7fr 3fr;
}

.dashboard-mobile {
  padding: 64px 20px 20px;
  grid-template-columns: 1fr;
}

.one-column {
  display: grid;
  row-gap: var(--spacingVerticalXL);
  grid-template-rows: 1fr 1fr;
}

.img-style {
  position: absolute;
  width: 100%;
  margin-left: -20px;
  margin-top: -64px;
  z-index: -1;
  max-height: 216px;
  min-height: 120px;
}

.spinner-layout {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
